<template>
  <main>
      <Header />
      <div class="card" v-if="cartItemCount==0">
        <div class="card-body text-center fs-2">
          <img src="/assets/imgs/cart_Monochromatic.png" class="w-25" alt="Empty Cart">
          <div class="card-title mt-4 fs-0">
            Your cart is empty
          </div>
        </div>
      </div>

      <div class="card" v-if="cartItemCount>0">
        <div class="card-header">
          <div class="row justify-content-between">
            <div class="col-md-auto">
              <h5 class="mb-3 mb-md-0">Shopping Cart ({{cartItemCount}} Items)</h5>
            </div>
            <div class="col-md-auto d-none d-lg-block">
              <router-link class="btn btn-sm btn-outline-secondary border-300 me-2" :to="{name:'Home', params: {storeId:$route.params.storeId}}">
                <span class="fas fa-chevron-left me-1" data-fa-transform="shrink-4"></span>Continue Shopping
              </router-link>
              <router-link class="btn btn-sm btn-primary" :to="{name:'Checkout', params: {storeId:$route.params.storeId, cartId:$route.params.cartId}}">Checkout</router-link>
            </div>
          </div>
        </div>
        <div class="card-body p-0">

          <div class="row gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
            <div class="col-9 col-md-8 py-2">Name</div>
            <div class="col-3 col-md-4">
              <div class="row">
                <div class="col-md-8 py-2 d-none d-md-block text-center">Quantity</div>
                <div class="col-12 col-md-4 text-end py-2">Price</div>
              </div>
            </div>
          </div>

          <div v-for="item in cartItems" :key="item.id" class="row gx-card mx-0 align-items-center border-bottom border-200">
            <div class="col-8 py-3">
              <div class="d-flex align-items-center">
                <router-link :to="{name: 'ProductDetails', params: {storeId:store.id, productId:item.product.slug}}">
                  <img class="img-fluid border ci_img rounded-1 me-3 d-none d-md-block" :src="item.product.product_images.length>0?item.product.product_images[0].path:placeholderImage" alt="" width="60" />
                </router-link>
                <div class="flex-1">
                  <h5 class="fs-0">
                    <router-link :to="{name: 'ProductDetails', params: {storeId:store.id, productId:item.product.slug}}" class="text-900">
                      {{item.product.name}}
                    </router-link>
                  </h5>
                  <div class="fs--2 fs-md--1"><a @click="removeFromCart(item.id)" class="text-danger" href="#!">Remove</a></div>
                </div>
              </div>
            </div>
            <div class="col-4 py-3">
              <div class="row align-items-center">
                <div class="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                  <div>
                    <div class="input-group input-group-sm flex-nowrap" data-quantity="data-quantity">
                      <button class="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button>
                      <input readonly class="form-control text-center px-2 input-spin-none" type="number" min="1" v-model="item.quantity" aria-label="Amount (to the nearest dollar)" style="width: 50px" />
                      <button class="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-end 2ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">{{item.product.store.currency_symbol}}
                  <span v-if="item.product.special_price != null || item.product.special_price != ''">{{item.product.special_price*item.quantity}}</span>
                  <span v-else>{{item.product.price*item.quantity}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row fw-bold gx-card mx-0">
            <div class="col-9 col-md-8 py-2 text-end text-900">Total</div>
            <div class="col px-0">
              <div class="row gx-card mx-0">
                <div class="col-md-8 py-2 d-none d-md-block text-center">{{cartItemCount}} (items)</div>
                <div class="col-12 col-md-4 text-end py-2">{{cartDetails.store.currency_symbol}} {{cartDetails.subtotal}}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer bg-light d-flex justify-content-end">
          <form class="me-3">
            <div class="input-group input-group-sm">
              <input class="form-control" type="text" placeholder="Promocode" />
              <button class="btn btn-outline-secondary border-300 btn-sm" type="submit">Apply</button>
            </div>
          </form>
          <router-link class="btn btn-sm btn-primary" :to="{name:'Checkout', params: {storeId:$route.params.storeId, cartId:$route.params.cartId}}">Checkout</router-link>
        </div>
      </div>
    <Footer />

    </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Checkout',
  components: {
    Header, Footer
  },
  metaInfo () {
    return {
      title: 'Checkout - ' + this.store.name
    }
  },
  data: function () {
    return {
      store: {
        currency_symbol: ''
      },
      cartItems: [],
      cartDetails: {
        store: {},
        coupon: {}
      },
      cartItemCount: 0
    }
  },
  methods: {
    removeFromCart (itemId) {
      return new Promise((resolve, reject) => {
        const self = this
        // get store details
        self.$storegence.delete('/sales/carts/' + self.$route.params.cartId + '/items/' + itemId + '/?store=' + localStorage.getItem('storeId')).then(res => {
          self.getCartItems().then(() => {
            self.cartItems = res.data.results
            self.cartItemCount = res.data.count
            setTimeout(() => {
              self.getCartDetails().then(res => {
                self.cartDetails = res.data
                self.getCartItems().then(res => {
                  self.cartItems = res.data.results
                  self.cartItemCount = res.data.count
                })
              })
            }, 1000)
          })
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  created () {
    const self = this
    self.getStoreData().then(res => {
      self.store = res.data
      self.getCartDetails().then(res => {
        self.cartDetails = res.data
        self.getCartItems().then(res => {
          self.cartItems = res.data.results
          self.cartItemCount = res.data.count
        })
      })
    })
  }
}
</script>

<style>
.r_box{
  height:auto !important;
  padding-bottom:10px !important;
  min-width: 100% !important;
}
.r_box_instructions{
  margin-left: 25px !important;
}
</style>
