<template>
  <div class="products">
    <Header :store="store" />

    <div v-loading="products.count==null" class="row">
      <div class="col-12">
        <h2 class="fs-2" v-if="$route.query.search==undefined">All Products</h2>
        <h2 class="fs-2" v-if="$route.query.search!==undefined">Search Results for "{{$route.query.search}}"</h2>
      </div>
      <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 mb-3" v-for="product in products.results" :key="product.id">
        <ProductBlock :store="store" :product="product" />
      </div>
      <div class="col-md-12">
        <Pagination :products="products" />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import ProductBlock from '@/components/ProductBlock.vue'
import Pagination from '@/components/Pagination.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Products',
  components: {
    Header, ProductBlock, Pagination, Footer
  },
  metaInfo () {
    return {
      title: 'Products - ' + this.store.name
    }
  },
  data () {
    return {
      store: {},
      products: {},
      featuredProducts: {}
    }
  },
  methods: {
    // getProducts (searchQuery = undefined) {
    //   return new Promise((resolve, reject) => {
    //     const self = this
    //     // get product list
    //     var url = '/products/product/?page_size=24&store='
    //     if (searchQuery !== undefined) {
    //       url = '/products/product/?page_size=24&search=' + searchQuery + '&store='
    //     }
    //     self.$storegence.get(url + localStorage.getItem('storeId')).then(res => {
    //       self.products = res.data
    //       // console.log(res.data)
    //       resolve(res)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // }
  },
  created () {
    this.getStoreData().then(res => {
      this.getQueriedProducts().then(res => {
        this.products = res.data
      })
      // this.getProducts(this.$route.query.search)
    })
  }
}
</script>
