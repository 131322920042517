<template>
  <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand-xl mb-3">
          <!-- <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button> -->
          <router-link class="navbar-brand me-0 me-sm-3 ml0i" :to="{name:'Home', params:{storeId: $route.params.storeId}}">
            <div class="d-flex align-items-center">
              <img class="border rounded me-2 ml0i" :src="store.logo===null?placeholderImage:store.logo" :alt="store.name" width="60">
              <h1 class="fs-3 mt-2 d-none d-sm-block">{{store.name}}</h1>
            </div>
          </router-link>
          <!-- <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
            <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
              <li class="nav-item">
                <router-link class="nav-link px-0 fa-icon-wait" :to="{name:'Products', params:{storeId: $route.params.storeId}}">
                  All Products
                </router-link>
              </li>
            </ul>
          </div> -->
          <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center mr0i">
            <li class="nav-item">
              <div class="theme-control-toggle fa-icon-wait px-2">
                <input class="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle" type="checkbox" data-theme-control="theme" value="dark">
                <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="" data-bs-original-title="Switch to light theme" aria-label="Switch to light theme">
                  <span class="fas fa-sun fs-0"></span>
                </label>
                <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="" data-bs-original-title="Switch to dark theme" aria-label="Switch to dark theme">
                  <span class="fas fa-moon fs-0"></span>
                </label>
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link px-0 fa-icon-wait" :to="{name:'Products', params:{storeId: $route.params.storeId}}">
                <span class="fas fa-box" data-fa-transform="shrink-7" style="font-size: 33px;"></span>
              </router-link>
            </li>
            <li class="nav-item">
              <a @click="gotoCart()" class="nav-link px-0 fa-icon-wait" href="#!">
                <span class="fas fa-shopping-cart" data-fa-transform="shrink-7" style="font-size: 33px;"></span>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait" href="/app/e-commerce/shopping-cart.html">
                <span class="fas fa-shopping-cart" data-fa-transform="shrink-7" style="font-size: 33px;"></span>
                <span class="notification-indicator-number">1</span>
              </a>
            </li> -->
          </ul>
        </nav>
</template>
<script>
export default {
  name: 'Header',
  data () {
    return {
      store: {},
      searchQuery: null
    }
  },
  created () {
    this.getStoreData()
  },
  methods: {
    search () {
      this.$router.push({
        name: 'Products',
        params: { storeId: this.store.id },
        query: { search: this.searchQuery }
      })
    },
    gotoCart () {
      const self = this
      if (self.$cookies.isKey('c')) {
        self.$router.push({ name: 'Cart', params: { storeId: self.store.id, cartId: self.$cookies.get('c') } })
      } else {
        this.$message({
          showClose: true,
          title: 'Add something first.',
          message: 'Your Cart is empty.'
        })
      }
    }
  }
}
</script>
<style>
.ml0i{
  margin-left: 0px !important;
}
.mr0i{
  margin-right: 0px !important;
}
</style>
