<template>
  <div class="index">
    <div class="row mt-5">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-6">
                <h1 class="fs-2">Storegence Checkout</h1>
                <p class="border-bottom pb-1 fs--1">
                  Signup on Storegence to setup your own quick checkout store. <br />
                  Mobile Friendly Shopping experience for your customers
                </p>
                <p class="fs--1">
                  Set up your store on Storegence and get more interesting features.
                </p>
                <div class="d-inline-block">
                  <a href="https://storegence.page.link/dnld" class="btn btn-falcon-primary">Get Started</a>
                </div>
              </div>
              <div class="col-md-6">
                <div class="w-100 text-center my-4">
                  <img class="w-50" src="/assets/imgs/Online_store _Monochromatic.png" alt="">
                </div>
                <p class="fs--1"></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Index',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
    // window.location.href = 'https://storegence.com'
  }
}
</script>
