<template>
  <main>
      <Header :store="store" />

      <div v-if="!isCartAvailable" class="row">
        <div class="row">
          <div class="col">
          </div>
          <div class="col-lg-5 col-md-8">
            <div class="card">
              <div class="card-body">
                Invalid Cart
              </div>
            </div>
          </div>
          <div class="col">
          </div>
        </div>
      </div>
      <div v-if="checkoutSuccessful" class="row">
            <CheckoutSuccess :order="order" />
      </div>

      <div class="row g-4" v-if="!checkoutSuccessful && isCartAvailable">
        <div class="col-md-5 col-lg-4 order-md-last">

          <div class="card" v-loading="cartDetails.id==undefined">
            <div class="card-header bg-light btn-reveal-trigger d-flex flex-between-center">
              <h5 class="mb-0">Order Summary</h5>
              <span class="badge badge-soft-primary">{{cartItemCount}}</span>
              <!-- <a class="btn btn-link btn-sm btn-reveal text-600" href="#"><span class="fas fa-pencil-alt"></span></a> -->
            </div>
            <div class="card-body">

              <table class="table table-borderless fs--1 mb-0">

                <tr class="border-bottom" v-for="item in cartItems" :key="item.id">
                  <th class="ps-0 pt-0 pt-1 pb-1">
                    <img class="rounded-1 float-start border ci_img p-0" :src="item.product.product_images.length>0?item.product.product_images[0].path:placeholderImage" alt="" />
                    {{item.product.name}}
                    <div class="text-400 fw-normal fs--2 p-0">x{{item.quantity}}</div>
                  </th>
                  <th class="pe-0 text-end pt-0">{{item.product.formatted_prices.special_price===null?item.product.formatted_prices.price:item.product.formatted_prices.special_price}}</th>
                </tr>

                <tr class="border-bottom">
                  <th class="ps-0 py-2">Subtotal</th>
                  <th class="pe-0 text-end">{{store.currency_symbol}} {{cartDetails.subtotal}}</th>
                </tr>
                <tr class="border-bottom">
                  <th class="ps-0 py-2">Shipping</th>
                  <th class="pe-0 text-end">
                    <strong class="px-0" v-if="cartDetails.shipping_cost!==null">{{store.currency_symbol}} {{cartDetails.shipping_cost}}</strong>
                    <a href="#!" v-if="cartDetails.shipping_cost===null">
                      <i class="fa fa-refresh"></i>
                    </a>
                  </th>
                </tr>
                <tr>
                  <th class="ps-0 pb-0 py-2">Total</th>
                  <th class="pe-0 text-end pb-0">{{store.currency_symbol}} {{cartDetails.total}}</th>
                </tr>

              </table>

            </div>
            <div class="card-footer d-flex justify-content-between bg-light">
              <div class="fw-semi-bold">Total Payable</div>
              <div class="fw-bold">{{store.currency_symbol}} {{cartDetails.total}}</div>
            </div>
          </div>

        </div>
        <div class="col-md-7 col-lg-8">

            <div class="card">

              <form action="#" @submit.prevent="initiateCheckout">

                <CheckoutCustomer ref="customer" :store="store" />

                <CheckoutAddress ref="address" />

                <CheckoutShippingMethods ref="shipping_method" />

                <CheckoutPaymentMethods ref="payment_method" v-if="cartDetails.shipping_method!==null" />

                <div class="card-body">
                  <div class="row mt-2 g-3 mt-2">
                    <div class="col-md-12">
                      <button v-loading="checkoutButtonLoader" :disabled="checkoutButtonLoader" type="submit" class="w-100 btn btn-primary btn-lg">{{cartDetails.shipping_method===null?'Calculate Shipping':'Complete checkout'}}</button>
                    </div>
                  </div>
                </div>

              </form>

            </div>

        </div>
      </div>
          <Footer />

    </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import CheckoutCustomer from '@/components/Checkout/Customer.vue'
import CheckoutAddress from '@/components/Checkout/Address.vue'
import CheckoutShippingMethods from '@/components/Checkout/ShippingMethods.vue'
import CheckoutPaymentMethods from '@/components/Checkout/PaymentMethods.vue'
import Footer from '@/components/Footer.vue'
import CheckoutSuccess from '@/components/Checkout/Success.vue'

export default {
  name: 'Checkout',
  components: {
    Header, CheckoutCustomer, CheckoutAddress, CheckoutShippingMethods, CheckoutPaymentMethods, Footer, CheckoutSuccess
  },
  metaInfo () {
    return {
      title: 'Checkout - ' + this.store.name
    }
  },
  data: function () {
    return {
      cartDetails: {
        coupon: {},
        discount: null
      },
      store: {},
      customer: {},
      address: {},
      cartItems: {},
      cartItemCount: 0,
      checkoutButtonLoader: false,

      order: {},

      isCartAvailable: true,
      checkoutSuccessful: false

    }
  },
  methods: {
    checkoutAddress (res) {
      const self = this
      return new Promise((resolve, reject) => {
        self.$refs.address.createAddress(res.data.customer).then(res => {
          self.$refs.address.setAddress(self.cartDetails, res.data).then(() => {
            self.$refs.address.setPage()
          }).then(res => {
            self.$refs.shipping_method.applyShippingMethod().then(res => {
              self.$refs.shipping_method.setPage()
            }).then((res) => {
              self.getCart()
              resolve(res)
            }).catch(err => reject(err))
          }).catch(err => reject(err))
        })
      })
    },
    initiateCheckout () {
      const self = this
      self.checkoutButtonLoader = true
      self.getCartDetails().then(res => {
        if (res.data.customer === null) {
          // if no customer, then create and set customer to cart
          self.$refs.customer.createCustomer().then(res => {
            self.$refs.customer.setCustomer(self.cartDetails.id, res.data.id).then(() => {
              self.$refs.customer.setPage()
              self.getCart().then(res => {
                self.checkoutAddress(res).then(() => {
                  self.checkoutButtonLoader = false
                }).catch(() => {
                  self.checkoutButtonLoader = false
                })
              })
            })
          }).catch(() => {
            self.checkoutButtonLoader = false
          })
        }
        if (res.data.customer !== null) {
          // customer already exist, create address to this customer
          if (res.data.shipping_address === null) {
            this.checkoutAddress(res).then(() => {
              self.checkoutButtonLoader = false
            }).catch(() => {
              self.checkoutButtonLoader = false
            })
          }
        }
        if (res.data.customer !== null && res.data.shipping_address !== null && res.data.shipping_method !== null) {
          console.log('finsihing...')
          this.$refs.payment_method.finishCheckout(res).then(res => {
            // checkout is finished here. take the user to congrats screen or whatever
            self.order = res.data
            self.checkoutSuccessful = true
          })
          self.checkoutButtonLoader = false
        }
      })
    },
    getCart () {
      const self = this
      return new Promise((resolve, reject) => {
        this.getCartDetails().then(res => {
          // console.log(res.data)
          self.cartDetails = res.data
          self.store = res.data.store
          self.customer = res.data.customer !== null ? res.data.customer : self.customer
          self.address = res.data.shipping_address !== null ? res.data.shipping_address : self.address

          resolve(res)
        }).catch(err => {
          self.isCartAvailable = false
          reject(err)
        })
      })
    }
  },
  created () {
    // get cart details
    this.getCart()

    this.getCartItems().then(res => {
      this.cartItems = res.data.results
      this.cartItemCount = res.data.count
    })
  },
  mounted () {
  }
}
</script>
<style>
.ci_img{
  width:50px !important;
  margin-right:5px !important;
  height: 50px;
}
</style>
