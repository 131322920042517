<template>
  <div class="pagination">

      <div class="">
        <el-button-group class="float-right">
          <el-button size="small" type="primary" icon="el-icon-arrow-left">Previous Page</el-button>
          <el-button size="small" type="primary">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
        </el-button-group>
      </div>

  </div>
</template>
<script>

export default {
  name: 'Pagination',
  components: {
  },
  props: {
    products: {
      type: Object
    }
  },
  methods: {
  },
  created () {
    // console.log(this.products)
  }
}
</script>
