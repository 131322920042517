<template>
  <div class="products">
    <Header :store="store" />

    <div class="page_content" v-html="page.content"></div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'StorePage',
  components: {
    Header, Footer
  },
  metaInfo () {
    return {
      title: this.page.name + ' - ' + this.store.name
    }
  },
  data () {
    return {
      store: {},
      page: {}
    }
  },
  methods: {
    getPageDetails () {
      const self = this
      return new Promise((resolve, reject) => {
        self.$storegence.get('/website/pages/search/?slug=' + self.$route.params.pageSlug + '&store=' + localStorage.getItem('storeId')).then(res => {
          self.page = res.data
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  created () {
    this.getStoreData().then(res => {
      this.getPageDetails()
    })
  }
}
</script>
