<template>
  <div class="card-body border-top" v-loading="Object.keys(address).length === 0 || loader">
    <div class="row flex-between-center mb-4">
      <div class="col-sm-auto">
        <h5 class="card-title vs-0 mb-2 mb-sm-0">Shipping and Billing Address</h5>
      </div>
      <div v-if="address.id!=undefined" class="col-sm-auto">
        <a @click="removeAddress" class="btn btn-falcon-danger btn-sm" href="#!">
          <span class="fas fa-times me-2" data-fa-transform="shrink-2"></span>
          Remove Address
        </a>
      </div>
    </div>

    <div class="row g-3 pb-2 mt-2">

      <div class="col-md-6 m-0">
        <label class="form-label" for="street">Street</label>
        <input class="form-control" id="street" type="text" name="street" placeholder="Address" v-model="address.street" :disabled="addressReadonly" required />
      </div>

      <div class="col-md-6 m-0">
        <label class="form-label" for="city">City</label>
        <input class="form-control" id="city" type="text" name="city" placeholder="City" v-model="address.city" :disabled="addressReadonly" required />
      </div>

      <div class="col-md-5 m-0 mt-3">
        <label class="form-label" for="country">Country</label>
        <select @change="address.state=null" class="form-select" name="country" id="country" aria-label="Select Country" v-model="address.country" :disabled="addressReadonly" required>
          <option disabled selected>Select Country</option>
          <option v-for="(country,index) in store.allowed_shipping_zones" :key="index" :value="index">{{index}}</option>
        </select>
      </div>

      <div class="col-md-4 m-0 mt-3">
        <label class="form-label" for="state">State/Province</label>
        <select class="form-select" name="state" id="state" aria-label="Select Country" v-model="address.state" :disabled="addressReadonly" required>
          <option disabled selected>Select State</option>
          <option v-for="state in store.allowed_shipping_zones[address.country]" :key="state" :value="state">{{state.substring(3)|capitalize}}</option>
        </select>
      </div>

      <div class="col-md-3 m-0 mt-3">
        <label class="form-label" for="zip">Zip</label>
        <input class="form-control" id="zip" name="zip" type="text" placeholder="Zip/Postcode" v-model="address.zip" :disabled="addressReadonly" required />
      </div>

    </div>
    <!-- {{address}} -->
  </div>
</template>

<script>
export default {
  name: 'CheckoutAddress',
  data: function () {
    return {
      addressReadonly: true,
      store: {},
      address: {},
      loader: false
    }
  },
  methods: {
    createAddress (customer) {
      this.loader = true
      return new Promise((resolve, reject) => {
        const self = this
        self.$storegence.post('/customers/customer/' + customer.id + '/addresses/?store=' + localStorage.getItem('storeId'), {
          street: self.address.street,
          city: self.address.city,
          state: self.address.state,
          zip: self.address.zip,
          country: self.address.country
        }).then(res => {
          self.address = res.data
          this.loader = false
          resolve(res)
        }).catch(err => {
          this.loader = false
          reject(err)
        })
      })
    },
    setAddress (cart, address) {
      return new Promise((resolve, reject) => {
        const self = this
        self.$storegence.patch('/sales/carts/' + cart.id + '/?store=' + localStorage.getItem('storeId'), {
          customer_id: cart.customer.id,
          shipping_address_id: address.id
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    removeAddress () {
      // console.log(this.$route.params.cartId)
      return new Promise((resolve, reject) => {
        const self = this
        self.$storegence.post('/sales/carts/remove_address/?store=' + localStorage.getItem('storeId'), {
          cart: self.$route.params.cartId
        }).then(res => {
          self.setPage()
          self.$message({
            showClose: true,
            message: 'Address removed from cart.'
          })
          self.$parent.getCart()
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    setPage () {
      this.getCartDetails().then(res => {
      // console.log(res.data)
        this.store = res.data.store
        this.address = res.data.shipping_address !== null ? res.data.shipping_address : { country: res.data.store.country }
        // eslint-disable-next-line no-unneeded-ternary
        this.addressReadonly = res.data.shipping_address === null ? false : true
      })
    }
  },
  created () {
    this.setPage()
  }
}
</script>

<style>

</style>
