<template>
  <div class="card-body mb-2" v-loading="Object.keys(customer).length === 0 || loader">
    <h5 class="card-title vs-0 mb-4">Customer Information</h5>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="row g-3">
          <div class="col-sm-12 m-0 mt-2">
            <label class="form-label" for="phone">Phone</label>
            <VuePhoneNumberInput :default-country-code="store.country" :no-flags="false" :clearable="!customerReadonly" :show-code-on-list="true" size="sm" name="phone" id="phone" @update="validateCustomerPhone" v-model="customerPhone" :disabled="customerReadonly" :required="true" />
          </div>

          <div class="col-sm-12 m-0 mt-2">
            <label class="form-label" for="name">Full Name</label>
            <input class="form-control" id="name" type="text" name="fullname" placeholder="Full Name" v-model="customer.name" :disabled="customerReadonly" required>
          </div>

          <div class="col-sm-12 m-0 mt-2">
            <label class="form-label" for="email">Email</label>
            <input class="mt-0 form-control" id="email" name="email" type="text" placeholder="Email Address" v-model="customer.email" :disabled="customerReadonly" required/>
          </div>
        </div>
        </div>
      </div>
      <!-- {{customer}} -->
  </div>
</template>

<script>

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'CheckoutCustomer',
  components: {
    VuePhoneNumberInput
  },
  props: {
    store: {
      type: Object
    }
  },
  data () {
    return {
      customerReadonly: true,
      customer: {},
      customerPhone: null,
      isCustomerPhoneValid: false,
      loader: false
    }
  },
  methods: {
    createCustomer () {
      if (Object.keys(this.customer).length < 3) {
        this.$message({ message: 'Customer information required' })
        return new Promise((resolve, reject) => {
          reject(Error('Please enter a valid Phone Number.'))
        })
      }
      if (!this.isCustomerPhoneValid) {
        this.$message({ message: 'Please enter a valid Phone Number.', showClose: true, type: 'warning' })
        return new Promise((resolve, reject) => {
          reject(Error('Please enter a valid Phone Number.'))
        })
      } else {
        return new Promise((resolve, reject) => {
          const self = this
          self.loader = true
          self.$storegence.post('/customers/customer/?store=' + localStorage.getItem('storeId'), {
            name: self.customer.name,
            email: self.customer.email,
            phone: self.customer.phone
          }).then(res => {
            // console.log(res)
            self.loader = false
            resolve(res)
          }).catch((err) => {
            // console.log(err.response.data)
            self.loader = false
            reject(err)
          })
        })
      }
    },
    setCustomer (cartId, customerId) {
      return new Promise((resolve, reject) => {
        const self = this
        self.$storegence.patch('/sales/carts/' + cartId + '/?store=' + localStorage.getItem('storeId'), {
          customer_id: customerId
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    validateCustomerPhone (event) {
      const self = this
      return new Promise((resolve, reject) => {
        if (event.isValid) {
          self.isCustomerPhoneValid = true
          self.customer.phone = event.e164
        } else {
          self.isCustomerPhoneValid = false
          self.customer.phone = ''
        }
      })
    },
    setPage () {
      this.getCartDetails().then(res => {
        this.customer = res.data.customer !== null ? res.data.customer : this.customer
        this.customerPhone = res.data.customer !== null ? res.data.customer.phone : ''
        // eslint-disable-next-line no-unneeded-ternary
        this.customerReadonly = res.data.customer !== null ? true : false
      })
    }
  },
  created () {
    this.setPage()
  }

}
</script>

<style>

</style>
