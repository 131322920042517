<template>
  <div class="card-body border-top" v-loading="Object.keys(paymentMethods).length === 0">
    <h5 class="card-title vs-0 mb-4">Select a Payment Method</h5>
    <div  class="row g-3 mt-3">

      <div class="col-md-4 m-0 mb-3" v-for="pm in paymentMethods" :key="pm.id">
        <div class="form-check mb-0 custom-radio radio-select">
          <input class="form-check-input" :id="pm.id" type="radio" name="paymentMethod" :value="pm.id" v-model="selectedPaymentMethod" required>
          <label class="form-check-label mb-0 fw-bold d-block" :for="pm.id">{{pm.name}}
            <span class="radio-select-content">
              <span v-if="pm.instructions===''||pm.instructions===null">No instructions provided</span>
              <span class="text-muted" v-else>{{pm.instructions}}</span>
            </span>
          </label>
        </div>
      </div>
      <!-- {{selectedPaymentMethod}} -->

    </div>
  </div>
</template>

<script>

export default {
  name: 'CheckoutPaymentMethods',
  components: {

  },
  data () {
    return {
      paymentMethods: false,
      loader: true,
      selectedPaymentMethod: null
    }
  },
  methods: {
    finishCheckout (res) {
      // console.log('finish checout')
      // console.log(this.selectedPaymentMethod)
      const self = this
      return new Promise((resolve, reject) => {
        self.$storegence.post('/sales/checkout/?store=' + res.data.store.id, {
          cart: res.data.id,
          payment_method: self.selectedPaymentMethod
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  created () {
    this.getPaymentMethods().then(res => {
      this.paymentMethods = res.data.results
    })
  }

}
</script>

<style>

</style>
