<template>
  <div class="card-body border-top mb-2" v-loading="Object.keys(cart).length === 0">
    <h5 class="card-title vs-0 mb-4">Select a Shipping Method</h5>
    <div  class="row g-3 mt-3">

      <div class="col-md-4 m-0 mb-3" v-for="sm in shippingMethods" :key="sm.id">
        <div class="form-check mb-0 custom-radio radio-select">
          <input class="form-check-input" :id="sm.id" type="radio" name="shippingMethod" :value="sm.id" v-model="selectedShippingMethod" :disabled="methodReadonly" required>
          <label class="form-check-label mb-0 fw-bold d-block" :for="sm.id">{{sm.name}}
            <span class="radio-select-content">
              <span v-if="sm.description==='' || sm.description===null">No description provided</span>
              <span class="text-muted" v-else>{{sm.description}}</span>
            </span>
          </label>
        </div>
      </div>
      <!-- {{selectedShippingMethod}} -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'CheckoutShippingMethods',
  components: {

  },
  data () {
    return {
      cart: {},
      shippingMethods: false,
      loader: true,
      methodReadonly: false,
      selectedShippingMethod: null
    }
  },
  methods: {
    applyShippingMethod () {
      return new Promise((resolve, reject) => {
        const self = this
        self.getCartDetails().then(res => {
          // apply shipping_method
          self.$storegence.post('/sales/carts/apply_shipping_method/?store=' + localStorage.getItem('storeId'), {
            cart: res.data.id,
            shipping_method: self.selectedShippingMethod
          }).then(res => {
            resolve(res)
          }).catch(err => {
            self.$message({
              message: err.response.data.error, type: 'error', showClose: true
            })
            reject(err)
          })
        })
      })
    },
    setPage () {
      this.getCartDetails().then(res => {
        this.cart = res.data
        this.getShippingMethods().then(res => {
          this.shippingMethods = res.data.results
          // eslint-disable-next-line no-unneeded-ternary
          // this.methodReadonly = this.cart.shipping_method == null ? false : true
        }).then(() => {
          this.selectedShippingMethod = this.cart.shipping_method !== null ? this.cart.shipping_method.id : null
          this.loader = false
        })
      })
    }
  },
  created () {
    this.setPage()
  }

}
</script>

<style>

</style>
