<template>
  <div class="store_footer mt-3">

    <div class="card">
      <div class="card-body">
        <span class="fs--1">
          {{store.name}} &copy; {{new Date().getFullYear()}} | <a href="#">Support</a> &middot; <a href="#">Terms</a> &middot; <a href="#">Privacy</a>
        </span>
      </div>
    </div>
    <!-- {{store}} -->

  </div>
</template>
<script>
export default {
  name: 'Footer',
  data () {
    return {
      store: {},
      searchQuery: null
    }
  },
  created () {
    this.getStoreData()
  },
  methods: {
    search () {
      this.$router.push({
        name: 'Products',
        params: { storeId: this.store.id },
        query: { search: this.searchQuery }
      })
    },
    gotoCart () {
      const self = this
      if (self.$cookies.isKey('c')) {
        self.$router.push({ name: 'Checkout', params: { storeId: self.store.id, cartId: self.$cookies.get('c') } })
      } else {
        this.$message({
          showClose: true,
          title: 'Add something first.',
          message: 'Your Cart is empty.'
        })
      }
    }
  }
}
</script>
<style>
.ml0i{
  margin-left: 0px !important;
}
.mr0i{
  margin-right: 0px !important;
}
</style>
