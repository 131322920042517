<template>
  <div class="product">
    <Header :store="store" />

    <div class="card mb-3">
      <div class="card-body">

        <div class="row" v-loading="product.name==null">
          <div class="col-md-4">
            <div>
              <img class="w-100 border rounded" :src="product.product_images.length>0?product.product_images[0].path:placeholderImage" :alt="product.name">
            </div>
          </div>
          <div class="col-md-8">
            <h2 class="fs-4 pb-2">{{product.name}}</h2>
            <h4 class="fs-1 text-warning">
              {{product.formatted_prices.special_price!==null?product.formatted_prices.special_price:product.formatted_prices.price}}
              <small class="text-muted"><del>{{product.formatted_prices.special_price!==null?product.formatted_prices.price:''}}</del></small>
            </h4>
            <p class="fs--1">
              Stock: <strong v-if="product.stock_quantity==null||product.stock_quantity>0" class="text-success">Available</strong><strong v-if="product.stock_quantity!=null&&product.stock_quantity<=0" class="text-danger">Out of Stock</strong>
            </p>
            <p class="pb-2 fs--1">
              <span v-if="product.sku!==null" class="d-block">SKU: {{product.sku}}</span>
              <span v-if="product.weight!==null"  class="d-block">Weight: {{product.weight}}</span>
              <span v-if="product.dimension!==null"  class="d-block">Dimension: {{product.dimension}}</span>
            </p>
            <span v-html="product.short_description"></span>
            <AddToCartButton :store="store" :product="product" />
          </div>
          <div class="col-md-12 mt-3 pt-3">
            <span v-html="product.description"></span>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div v-if="upsellProducts.count>0" v-loading="upsellProducts.count==null" class="row mb-3">
        <div class="col-12">
          <h2 class="fs-2">Similar Products</h2>
        </div>
        <div :class="productBlockClass" v-for="product in upsellProducts.results" :key="product.id">
          <ProductBlock :store="store" :product="product" />
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="crossSellProducts.count>0" v-loading="crossSellProducts.count==null" class="row mb-3">
        <div class="col-12">
          <h2 class="fs-2">You might be interested in</h2>
        </div>
        <div :class="productBlockClass" v-for="product in crossSellProducts.results" :key="product.id">
          <ProductBlock :store="store" :product="product" />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import ProductBlock from '@/components/ProductBlock.vue'

import AddToCartButton from '@/components/AddToCartButton.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'ProductDetails',
  components: {
    Header, AddToCartButton, Footer, ProductBlock
  },
  metaInfo () {
    return {
      title: this.product.name + ' - ' + this.store.name,
      meta: [
        { name: 'description', content: this.product.short_description },
        { property: 'og:title', content: this.product.name },
        { property: 'og:image', content: this.product.product_images.length > 0 ? this.product.product_images[0].path : '' },
        { property: 'og:description', content: this.product.short_description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'article' }
      ]
    }
  },
  data () {
    return {
      store: {},
      crossSellProducts: {},
      upsellProducts: {},
      product: {
        product_images: [],
        formatted_prices: {}
      }
    }
  },
  methods: {
    getProduct () {
      return new Promise((resolve, reject) => {
        const self = this
        // get store details
        self.$storegence.get('/products/product/search/?slug=' + self.$route.params.productId + '&store=' + localStorage.getItem('storeId')).then(res => {
          self.product = res.data
          // console.log(res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getPage () {
      const self = this
      this.getStoreData().then(res => {
        this.getProduct().then(() => {
          if (self.product.upsell_products.length > 0) {
            this.getQueriedProducts('&ids=' + self.product.upsell_products.toString()).then(res => {
              self.upsellProducts = res.data
            })
          }
          if (self.product.cross_sell_products.length > 0) {
            this.getQueriedProducts('&ids=' + self.product.cross_sell_products.toString()).then(res => {
              self.crossSellProducts = res.data
            })
          }
        })

        this.getQueriedProducts('&is_featured=true').then(res => {
          self.featuredProducts = res.data
        })
      })
    }
  },
  mounted () {
    this.getPage()
  },
  watch: {
    '$route.params.productId': {
      handler: function (search) {
        this.getPage()
        window.scrollTo(0, 0)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
