<template>
    <div class="col-12">

      <div class="card">
        <div class="card-body text-center fs-2">
          <img src="/assets/imgs/Order_Confirmed_Monochromatic.png" class="w-25" alt="Empty Cart">
          <div class="card-title mt-4 fs-0">
            <div class="card-title text-success">Thank You</div>
            <span class="d-block">Your order has been received.</span>
            <small class="d-block">Order ID # {{order.order.id}}</small>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
export default {
  name: 'CheckoutSuccess',
  data: function () {
    return {
    }
  },
  props: {
    order: {
      type: Object
    }
  },
  mounted () {
    if (this.order.payment.type === 'auto' && this.order.payment.url != null) {
      window.location.href = this.order.payment.url
    }
  }
}
</script>

<style>

</style>
