<template>
  <div class="card product-block">
    <router-link :to="{name: 'ProductDetails', params: {storeId:store.id, productId:product.slug}}" class="bp_image">
      <img class="card-img-top h-100" :src="product.product_images.length>0?product.product_images[0].path:placeholderImage" :alt="product['name']">
    </router-link>
    <div class="card-body">
      <h6 class="">
        <router-link :to="{name: 'ProductDetails', params: {storeId:store.id, productId:product.slug}}" class="text-decoration-none text-dark"> {{product.name}}</router-link>
      </h6>
      <h6 class="card-text">
        {{product.formatted_prices.special_price != null ? product.formatted_prices.special_price: product.formatted_prices.price}}
        <span v-if="product.special_price!=null" class="text-white bg-danger badge badge-danger">On Sale</span>
      </h6>
    </div>
    <div class="card-footer pt-0">
      <AddToCartButton :store="store" :product="product" />
    </div>
  </div>
</template>
<script>

import AddToCartButton from '@/components/AddToCartButton.vue'
export default {
  name: 'ProductBlock',
  components: {
    AddToCartButton
  },
  props: {
    store: {
      type: Object
    },
    product: {
      type: Object
    }
  }
}
</script>
<style>
.product-block{
  min-height: 100%;
}
.bp_image{
  height: 200px; /* 190 */
  /* max-width: 100%; */
}
@media only screen and (max-device-width: 480px) {
  .bp_image{
    height: 150px;
    /* max-width: 100%; */
  }
}
</style>
