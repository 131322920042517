<template>
  <div class="my-2">
    <button v-if="product.type=='simple'" @click="addToCart(product)" v-loading="isDisabled" :disabled="isDisabled" class="btn btn-falcon-primary btn-sm" type="button">
      <span class="fas fa-shopping-cart me-1" data-fa-transform="shrink-3"></span>
      <span class="fs--1 d-none d-lg-inline-block">{{store.add_to_cart_button_text}}</span>
    </button>
    <a :href="product.external_url" target="_blank" v-if="product.type=='external'" v-loading="isDisabled" :disabled="isDisabled" class="btn btn-falcon-primary btn-sm" type="button">
      <span class="fa fa-external-link-alt me-1" data-fa-transform="shrink-3"></span>
      <span class="fs--1 d-none d-lg-inline-block">{{product.external_button_text}}</span>
    </a>
    <!-- <el-button @click="addToCart(product)" v-loading="isDisabled" size="small" type="primary">
      {{store.add_to_cart_button_text}}
    </el-button> -->
  </div>
</template>
<script>
export default {
  name: 'AddToCartButton',
  props: {
    store: {
      type: Object
    },
    product: {
      type: Object
    }
  },
  data () {
    return {
      isDisabled: false
    }
  },
  methods: {
    createCart () {
      return new Promise((resolve, reject) => {
        const self = this
        // create new cart
        self.$storegence.post('/sales/carts/?store=' + self.store.id).then(res => {
        // create a cookie
          self.$cookies.set('c', res.data.id)
          resolve(res.data.id)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getOrCreateCart () {
      return new Promise((resolve, reject) => {
        const self = this
        if (self.$cookies.isKey('c')) {
          // validate the cookie
          self.$storegence.get('/sales/carts/' + self.$cookies.get('c') + '/?store=' + localStorage.getItem('storeId')).then(res => {
            // create a cookie
            resolve(self.$cookies.get('c'))
          }).catch(() => {
            self.createCart().then(() => {
              resolve(self.$cookies.get('c'))
            })
          })
        } else {
          resolve(self.createCart())
        }
      })
    },
    addItemToCart (cartId, product) {
      return new Promise((resolve, reject) => {
        const self = this
        // get store details
        self.$storegence.post(
          '/sales/carts/' + cartId + '/items/?store=' + localStorage.getItem('storeId'),
          { product_id: product.id }
        ).then(res => {
          self.$message({ showClose: true, title: 'Product Added to cart', message: product.name + ' is added to your cart.', type: 'success' })
          // console.log(res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    addToCart (product) {
      const self = this
      self.isDisabled = true
      self.getOrCreateCart().then(cartId => {
        self.addItemToCart(cartId, product).then(() => {
          // do something here in the future
          self.isDisabled = false
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>
