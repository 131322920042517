<template>
  <div class="products">
    <Header />

    <div v-if="featuredProducts.count>0" v-loading="featuredProducts.count==null" class="row mb-3">
      <div class="col-12">
        <h2 class="fs-2">Featured</h2>
      </div>
      <div :class="productBlockClass" v-for="product in featuredProducts.results" :key="product.id">
        <ProductBlock :store="store" :product="product" />
      </div>
    </div>

    <div v-if="products.count>0" v-loading="products.count==null" class="row">
      <div class="col-12">
        <h2 class="fs-2">Products</h2>
      </div>
      <div :class="productBlockClass" v-for="product in products.results" :key="product.id">
        <ProductBlock :store="store" :product="product" />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import ProductBlock from '@/components/ProductBlock.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header, ProductBlock, Footer
  },
  metaInfo () {
    return {
      title: this.store.name
    }
  },
  data () {
    return {
      store: {},
      products: {},
      featuredProducts: {}
    }
  },
  methods: {
  },
  created () {
    this.getStoreData().then(res => {
      this.getQueriedProducts().then(res => {
        this.products = res.data
      })
      this.getQueriedProducts('&is_featured=true').then(res => {
        this.featuredProducts = res.data
      })
    })
  }
}
</script>
